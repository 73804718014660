import React, { Component } from "react";
import CommonModal from "../CommonModal";
import TableForm from "../TableForm";
import enums from "../../../enums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../Services/ApiService";
import utils from "../../Services/Utils";

const paymentObjectKeys = {
  transactionId: "",
  transactionDate: "",
  transactionAmount: "",
  paymentMethod: "",
};

class EditEnrollmentModal extends Component {
  constructor(props) {
    super(props);

    const enrollment = this.props.enrollment;
    const enrollmentObj = {};
    for (const key in enrollment.bootcamp) {
      enrollmentObj[key] = enrollment.bootcamp[key];
    }

    enrollmentObj.progress = enrollment.progress;
    enrollmentObj.completed = enrollment.completed;

    for (const key in enrollment.certificate) {
      enrollmentObj["certificate." + key] = enrollment.certificate[key];
    }

    for (const key in enrollment.payment || paymentObjectKeys) {
      enrollmentObj["payment." + key] = enrollment.payment ? enrollment.payment[key] : "";
    }
    this.state = {
      enrollment: enrollmentObj,
    };
  }

  async editEnrollment(event) {
    try {
      event.preventDefault();
      let enrollmentDataJson = JSON.stringify(this.state.enrollment);
      enrollmentDataJson = utils.addSlashes(enrollmentDataJson);
      await api.updateEnrollment(this.state.enrollment.bootcampId, this.props.user.id, enrollmentDataJson);
      this.props.refreshUsers();
      this.props.toggle();
    } catch (ex) {
      alert("Error occured: ", ex);
    }
  }

  render() {
    console.log(this.props, this.state);
    return (
      <CommonModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        header={
          <span>
            Edit Enrollment of <span className="text-primary">{this.state.enrollmentBasic?.title}</span>
          </span>
        }
      >
        <TableForm onSubmit={this.editEnrollment.bind(this)}>
          {Object.keys(this.state?.enrollment || {}).map((key) => {
            const value = this.state.enrollment[key];
            if (key === "__typename" || key.startsWith("payment.")) return null;
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    name={key}
                    onChange={(event) => {
                      const val = event.target.value;
                      this.setState((state) => {
                        state.enrollment[key] = val;
                        return state;
                      });
                    }}
                    value={value || ""}
                  />
                </td>
              </tr>
            );
          })}
          {this.state.enrollment && (
            <React.Fragment>
              <tr>
                <td>Transaction Id</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    name={"payment.transactionId"}
                    onChange={(event) => {
                      const val = event.target.value;
                      this.setState((state) => {
                        state.enrollment["payment.transactionId"] = val;
                        return state;
                      });
                    }}
                    value={this.state.enrollment["payment.transactionId"] || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>Date</td>
                <td>
                  <DatePicker
                    className="form-control"
                    selected={this.state.enrollment["payment.transactionDate"] ? new Date(parseInt(this.state.enrollment["payment.transactionDate"])) : undefined}
                    onChange={(date) => {
                      this.setState((state) => {
                        state.enrollment["payment.transactionDate"] = date.getTime();
                        return state;
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    name={"payment.transactionAmount"}
                    onChange={(event) => {
                      const val = event.target.value;
                      this.setState((state) => {
                        state.enrollment["payment.transactionAmount"] = val;
                        return state;
                      });
                    }}
                    value={this.state.enrollment["payment.transactionAmount"] || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>Method</td>
                <td>
                  <select
                    className="form-control"
                    onChange={(event) => {
                      const val = event.target.value;
                      this.setState((state) => {
                        state.enrollment["payment.paymentMethod"] = val;
                        return state;
                      });
                    }}
                    value={this.state.enrollment["payment.paymentMethod"] || ""}
                  >
                    <option value="">Select Method</option>
                    {Object.keys(enums.PAYMENT_METHOD).map((paymentMethod) => {
                      return (
                        <option key={paymentMethod} value={paymentMethod}>
                          {paymentMethod}
                        </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <td></td>
            <td>
              <input className="btn btn-success" type="submit" defaultValue="Add" />
            </td>
          </tr>
        </TableForm>
      </CommonModal>
    );
  }
}

export default EditEnrollmentModal;
