import React from "react";
import { Route, NavLink } from "react-router-dom";
import Home from "./Pages/Home";
import Users from "./Pages/Users";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  // NavItem
} from "reactstrap";
import Login from "./Pages/Login";
import config from "../config";
import auth from "./Services/AuthService";
import enums from "../enums";
import Loading from "./Components/Loading";
import ErrorLog from "./Pages/ErrorLog";
import Roles from "./Pages/Roles";
import Mautic from "./Pages/Mautic/Mautic";
import UserApplication from "./Pages/UserApplication";
import Schedule from "./Pages/Schedule/Schedule";
import Settings from "./Pages/Settings/Settings";
import Zoom from "./Pages/Zoom/Zoom";
import ChatQuestion from "./Pages/ChatQuestion";

const MyContext = React.createContext({ hmd: true });

class Header extends React.Component {
  state = {
    context: {
      history: this.props.history,
      setContext: this.setContext.bind(this),
    },
    isNavBarOpen: false,
  };
  componentDidMount() {
    //console.log(this);
    // // this Code is to replace '#!' in location.
    // const history = createBrowserHistory();
    // const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    // if (path) {
    //   history.push(path);
    // }
  }

  setContext(ctxObj) {
    const newContext = this.state.context;
    for (const key in ctxObj) {
      newContext[key] = ctxObj[key];
    }
    this.setState({ context: newContext });
  }

  onMenuItemClick = (event, menuItem) => {
    this.setState({ isNavBarOpen: false });
    if (menuItem.onClick) {
      menuItem.onClick(event);
    }
  };

  logout = () => {
    auth.setToken(null);
    window.location.assign("/");
  };

  login = () => {
    const url = `${config.BACKEND_URL}/login/github?needToken=1&appRedirectUrl=${config.APP_ROOT_URL}/login`;
    window.location.assign(url);
  };

  toggleNavBar = () => {
    this.setState({ isNavBarOpen: !this.state.isNavBarOpen });
  };

  menuItem = [
    {
      labelName: "Users",
      actionLink: "/users",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Users Application",
      actionLink: "/usersapplication",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Error Log",
      actionLink: "/errorlog",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Roles",
      actionLink: "/roles",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Chat Question",
      actionLink: "/chatQuestion",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Mautic",
      actionLink: "/mautic",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Schedule",
      actionLink: "/schedule",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Settings",
      actionLink: "/settings",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Zoom",
      actionLink: "/zoom",
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },

    {
      labelName: "Logout",
      actionLink: "",
      onClick: this.logout,
      visibility: enums.MENU_VISIBILITY.LOGGED,
    },
    {
      labelName: "Login",
      actionLink: "",
      onClick: this.login,
      visibility: enums.MENU_VISIBILITY.NOT_LOGGED,
    },
  ];

  render() {
    const isuserlogged = auth.isLogged();
    return (
      <div>
        <Navbar color="info" light expand="md">
          <div className="nav-brand">
            <NavLink className="nav-link text-white" to="/">
              CG Admin
            </NavLink>
          </div>
          <NavbarToggler onClick={this.toggleNavBar} />
          <Collapse isOpen={this.state.isNavBarOpen} navbar>
            <Nav className="ml-auto" navbar>
              {React.Children.toArray(
                this.menuItem.map((item, index) => {
                  if (item.visibility === enums.MENU_VISIBILITY.NOT_LOGGED && isuserlogged) {
                    return null;
                  }
                  if (item.visibility === enums.MENU_VISIBILITY.LOGGED && !isuserlogged) {
                    return null;
                  }
                  return (
                    <NavLink
                      key={index.id}
                      to={item.actionLink}
                      onClick={(e) => {
                        this.onMenuItemClick(e, item);
                      }}
                      className="nav-link text-white"
                    >
                      {item.labelName}
                    </NavLink>
                  );
                }),
              )}
            </Nav>
          </Collapse>
        </Navbar>
        <MyContext.Provider value={this.state.context}>
          <Route exact path={`${this.props.match.url}`} component={Home} />
          <div>
            <Route exact path={`${this.props.match.url}users`} component={Users} />
            <Route exact path={`${this.props.match.url}users/role/:role`} component={Users} />
            <Route exact path={`${this.props.match.url}users/course/:course`} component={Users} />
            <Route exact path={`${this.props.match.url}usersapplication`} component={UserApplication} />
            <Route exact path={`${this.props.match.url}login`} component={Login} />
            <Route exact path={`${this.props.match.url}errorlog`} component={ErrorLog} />

            <Route exact path={`${this.props.match.url}roles`} component={Roles} />
            <Route exact path={`${this.props.match.url}chatQuestion`} component={ChatQuestion} />
            <Route exact path={`${this.props.match.url}mautic`} component={Mautic} />
            <Route exact path={`${this.props.match.url}schedule`} component={Schedule} />
            <Route exact path={`${this.props.match.url}settings`} component={Settings} />
            <Route exact path={`${this.props.match.url}zoom`} component={Zoom} />
          </div>
          <Loading></Loading>
        </MyContext.Provider>
      </div>
    );
  }
}

export default Header;

export { MyContext };
