import React from "react";
import { Input } from "reactstrap";

class RenderUtils {
  renderTextField(componentThis, stateKey, placeholder = "", options = {}) {
    return (
      <Input
        type={options.type || "text"}
        {...options}
        placeholder={placeholder}
        value={componentThis.state[stateKey]}
        onChange={(e) => {
          const obj = {};
          obj[stateKey] = e.target.value;
          componentThis.setState(obj);
        }}
      />
    );
  }
  renderCheckbox(componentThis, stateKey, placeholder = "", options = {}) {
    return (
      <input
        type={options.type || "checkbox"}
        {...options}
        placeholder={placeholder}
        checked={componentThis.state[stateKey]}
        onChange={(e) => {
          componentThis.setState({ [stateKey]: e.target.checked });
        }}
      />
    );
  }
}

const renderUtils = new RenderUtils();

export default renderUtils;
