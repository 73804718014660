import React, { Component } from "react";
import api from "../Services/ApiService";
import CommonModal from "../Components/CommonModal";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

class ErrorLog extends Component {
  state = {
    lstErrorLog: [],
    isJsonShown: false,
    errorLogJson: "",
  };

  componentDidMount() {
    this.loadErrorLog();
  }

  async loadErrorLog() {
    try {
      let result = await api.errorLog();
      result = result.map((item) => {
        return { ...item, time: new Date(parseInt(item.time)).toLocaleString() };
      });
      this.setState({ lstErrorLog: result });
    } catch (ex) {
      alert(ex);
    }
  }

  viewErrorJson = async (errorId) => {
    const errorJson = await api.errorLogInfo(errorId);
    if (!errorJson[0]) return alert("Json is not available. ");
    //console.log(errorJson[0].json)
    await this.setState({ errorLogJson: errorJson[0].json, isJsonShown: true });
  };

  render() {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <td>Message</td>
              <td>Time</td>
              <td>View</td>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(
              this.state.lstErrorLog.map((error, index) => {
                return (
                  <tr key={index.id}>
                    <td>{error.message}</td>
                    <td>{error.time}</td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          this.viewErrorJson(error._id);
                        }}
                      >
                        Json
                      </button>
                    </td>
                  </tr>
                );
              }),
            )}
          </tbody>
        </table>
        <CommonModal
          header="Error Log Json"
          isOpen={this.state.isJsonShown}
          toggle={() => {
            this.setState({ isJsonShown: !this.state.isJsonShown });
          }}
        >
          <JSONPretty data={this.state.errorLogJson} />
        </CommonModal>
      </div>
    );
  }
}

export default ErrorLog;
