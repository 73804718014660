const enums = {
  MENU_VISIBILITY: {
    LOGGED: 1,
    NOT_LOGGED: 2,
    BOTH: 0,
  },
  PAYMENT_METHOD: {
    STRIPE: "STRIPE",
    CHEQUE: "CHEQUE",
    OTHER: "CHEQUE",
  },
  USER_ROLES: {
    ADMIN: "admin",
    USER: "user",
    MLAI: "mlai",
    CLOUD: "cloud",
    FINTECH: "fintech",
    CLOUDWORKSHOP: "cloudworkshop",
    TCS: "tcs",
  },
  SCHEDULE_DAY_TYPE: {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
  },
  SCHEDULE_TYPE: {
    ONE_TIME: "ONE_TIME",
    REPEAT: "REPEAT",
  },
  ZOOM_RECORDING_TYPE: {
    SHARED_SCREEN: "shared_screen",
    SPEAKER_VIEW: "speaker_view",
  },
};

export default enums;
