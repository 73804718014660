import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // Button,
  Table,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import api from "../../Services/ApiService";
// import utils from '../../Services/Utils';
// import moment from 'moment';
// import api from '../../Services/ApiService/index';
// import Datatable from "react-bs-datatable";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = { settings: {}, activeTab: 0, settingsSchema: [] };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    try {
      const initialSettings = {};
      const settingsSchema = await api.getSettings();
      console.log(settingsSchema);
      settingsSchema.forEach((setting) => {
        if (setting.settings) {
          setting.settings.forEach((innerSetting) => {
            initialSettings[innerSetting.key] = "";
          });
        }
      });
      const settings = await api.getDbConfig(Object.keys(initialSettings));
      this.setState({ settings, settingsSchema, activeTab: settingsSchema[0].key });
    } catch (ex) {
      alert("Error occurred while loading settings " + ex.message);
    }
  }

  setSettingsState(obj) {
    const settings = { ...this.state.settings, ...obj };
    this.setState({ settings });
  }

  getValueField(setting) {
    switch (setting.type) {
      case "BOOLEAN":
        return (
          <input
            type="checkbox"
            checked={this.state.settings[setting.key]}
            onChange={async (e) => {
              await this.setSettingsState({ [setting.key]: e.target.checked });
              this.setDbConfig(setting);
            }}
          />
        );
      case "STRING":
        return (
          <input
            className="form-control"
            type="text"
            value={this.state.settings[setting.key] || ""}
            onChange={(e) => {
              this.setSettingsState({ [setting.key]: e.target.value });
            }}
          />
        );
      case "TEXT":
        return (
          <textarea
            className="form-control"
            type="text"
            value={this.state.settings[setting.key] || ""}
            onChange={(e) => {
              this.setSettingsState({ [setting.key]: e.target.value });
            }}
          ></textarea>
        );
      default:
        return (
          <input
            className="form-control"
            type="text"
            value={this.state.settings[setting.key] || ""}
            onChange={(e) => {
              this.setSettingsState({ [setting.key]: e.target.value });
            }}
          />
        );
    }
  }

  setDbConfig = async (setting) => {
    try {
      await api.setDbConfig(setting.key, this.state.settings[setting.key], setting.type);
    } catch (ex) {
      alert("Error occurred while setting settings " + ex.message);
    }
  };

  renderSettings(lstSettings) {
    return (
      <Table>
        <tbody>
          {lstSettings.map((item) => {
            return (
              <tr key={item.key}>
                <td>{item.label || item.key}</td>
                <td>{this.getValueField(item)}</td>
                <td>
                  {item.type === "BOOLEAN" ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        this.setDbConfig(item);
                      }}
                    >
                      Save
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {/* <Button color="primary" onClick={this.onClickAdd.bind(this)} >Add Schedule</Button> */}
                <Nav tabs>
                  {this.state.settingsSchema.map((item) => {
                    return (
                      <NavItem key={item.key}>
                        <NavLink
                          className={this.state.activeTab === item.key ? "active" : ""}
                          onClick={() => {
                            this.setState({ activeTab: item.key });
                          }}
                        >
                          {item.label}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.settingsSchema.map((item) => {
                    return (
                      <TabPane tabId={item.key} key={item.key}>
                        {this.renderSettings(item.settings)}
                      </TabPane>
                    );
                  })}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Settings;
