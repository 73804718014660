import React, { Component } from "react";
import CommonModal from "../../Components/CommonModal";
import { Table, Button } from "reactstrap";
import renderUtils from "../../Services/RenderUtils";
import Select from "react-select";
import utils from "../../Services/Utils";

class EditContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.contact.firstName || "",
      lastName: this.props.contact.lastName || "",
      mobile: this.props.contact.mobile || "",
      tagsStr: this.props.contact.tagsStr || "",
      points: this.props.contact.points,
      tags: utils.GroupObject(this.props.contact.tags).map((item) => ({ label: item.tag, value: item.tag })),
    };
  }

  render() {
    return (
      <CommonModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        header={
          <span>
            Edit Contact <span className="text-primary"></span>
          </span>
        }
      >
        <Table striped>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{renderUtils.renderTextField(this, "firstName", "First Name")}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{renderUtils.renderTextField(this, "lastName", "Last Name")}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>{renderUtils.renderTextField(this, "mobile", "Mobile Number", "text")}</td>
            </tr>
            <tr>
              <td>Points</td>
              <td>{renderUtils.renderTextField(this, "points", "Points", "number")}</td>
            </tr>
            <tr>
              <td>Tags</td>
              <td>
                <Select
                  onChange={(values) => {
                    this.setState({ tags: values });
                  }}
                  options={this.props.allTags}
                  value={this.state.tags}
                  isMulti
                  placeholder="Tags"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.onSubmit(this.state);
                  }}
                >
                  Update
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </CommonModal>
    );
  }
}

export default EditContactModal;
