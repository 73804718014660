import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
// import moment from 'moment';
import api from "../../Services/ApiService/index";
import utils from "../../Services/Utils";

import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "@bit/adeoy.utils.data-table";

class Zoom extends Component {
  state = {
    objZoomRecordings: null,
  };

  columns = [
    { title: "Action", data: "action" },
    { title: "Meeting ID", data: "id" },
    { title: "Start Time", data: "startTime" },
    { title: "Recording Status", data: "recordingStatus" },
    { title: "Play", data: "btnPlay" },
    { title: "Download Progress", data: "download_progress" },
  ];

  componentDidMount() {
    // console.log("Loadn Called")
    this.getRecordings();
  }

  async getRecordings() {
    try {
      // console.log("Called")
      const strRecordings = await api.getZoomRecordings();
      // console.log(strRecordings)
      const objZoomRecordings = JSON.parse(strRecordings);
      objZoomRecordings.meetings.forEach((meeting) => {
        // console.log(meeting);
        meeting.recording = meeting.recording_files.find((item) => item.recording_type === utils.getZoomRecordingType());
        if (!meeting.recording) {
          meeting.recording = meeting.recording_files[0];
        }
        if (meeting.recording) {
          meeting.recordingStatus = meeting.recording.status;
          meeting.startTime = utils.convertToZoomRecordingFormat(meeting.start_time);
          meeting.btnPlay = (
            <a className="btn btn-sm btn-info" href={meeting.recording.play_url} target="_blank" rel="noopener noreferrer">
              Play
            </a>
          );
          meeting.action = (
            <React.Fragment>
              {!meeting.hideDownload && (
                <Button color={meeting.downloaded ? "warning" : "primary"} size="sm" onClick={this.onStartDownloadClick.bind(this, meeting.recording, meeting)}>
                  {meeting.downloaded ? "Re-Download" : "Start Download"}
                </Button>
              )}{" "}
            </React.Fragment>
          );
        }
      });

      this.setState({ objZoomRecordings });
    } catch (ex) {
      alert(ex);
    }
  }

  onClickAdd() {}

  async onStartDownloadClick(recording, meeting) {
    try {
      await api.downloadZoomRecording(JSON.stringify(meeting));
      // console.log(result)
      this.getRecordings();
    } catch (ex) {
      alert(ex);
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {/* <Button color="primary" onClick={this.onClickAdd.bind(this)} >Add Schedule</Button> */}
                {this.state.objZoomRecordings && <DataTable data={this.state.objZoomRecordings.meetings} columns={this.columns} striped={true} hover={true} responsive={true} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Zoom;
