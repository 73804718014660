import lsu from "./LocalStorageUtils";
import moment from "moment";
import "moment-timezone";
import enums from "../../enums";

class Utils {
  GroupObject(object) {
    return Object.keys(object).map((key) => {
      if (key.startsWith("__"))
        // This is for removing __typename came with Apollo GQL client query.
        return null;
      return object[key];
    });
  }

  TrimArray(array) {
    return array.filter((item) => item);
  }

  showLoading = function (type) {
    if (type === "none") return;
    const divId = type === "small" ? "divLoadingSmall" : "divLoading";
    if (document.getElementById(divId)) {
      document.getElementById(divId).style.display = "block";
    }
  };

  hideLoading = function () {
    if (document.getElementById("divLoading")) {
      document.getElementById("divLoading").style.display = "none";
    }
    if (document.getElementById("divLoadingSmall")) {
      document.getElementById("divLoadingSmall").style.display = "none";
    }
  };

  toFixed = (value, points) => {
    try {
      //console.log(value, parseFloat(value).toFixed(points));
      let float = parseFloat(value);
      if (!float) {
        float = 0;
      }
      return float.toFixed(points);
    } catch (ex) {
      console.log(`Cant convert ${value} toFixed(${points})`);
      return value;
    }
  };

  CopyObject = (obj) => {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch (ex) {
      return null;
    }
  };

  addSlashes = (string) => {
    console.log(string);
    return string.replace(/\\/g, "\\\\").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\f/g, "\\f").replace(/\r/g, "\\r").replace(/'/g, "\\\\'").replace(/"/g, '\\"');
  };

  removeFromArray(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  withCache(apiFunction, key, expireDateOrExpireAfterInMinutes = null) {
    var data = lsu.lsGet(key);
    if (data) {
      return () => {
        return data;
      };
    }
    return async (...args) => {
      data = await apiFunction(...args);
      lsu.lsSet(key, data, expireDateOrExpireAfterInMinutes);
      return data;
    };
  }

  sortArrayOfObject(list, sortBy, sortOrder = "asc") {
    list.sort((aa, bb) => {
      var a = aa,
        b = bb;
      if (sortOrder === "desc") {
        const c = aa;
        a = bb;
        b = c;
      }
      if (typeof a[sortBy] == "number") {
        return a[sortBy] - b[sortBy];
      }
      const nameA = a[sortBy];
      const nameB = b[sortBy];
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  convertToSystemDateFormat(date) {
    return moment(date).format("MMMM D, YYYY h:mm A");
  }
  loadMomentTZ() {
    moment.tz.load({
      version: "2021a",
      zones: ["America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0", "America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0"],
      links: ["America/Los_Angeles|US/Pacific", "America/New_York|US/Eastern"],
    });
  }

  convertToZoomRecordingFormat(date) {
    this.loadMomentTZ();
    return moment(date).tz("America/Los_Angeles").format("YYYY-MM-DD-HH-mm");
  }

  getZoomRecordingType() {
    return enums.ZOOM_RECORDING_TYPE.SHARED_SCREEN;
  }
}

const utils = new Utils();
export default utils;
