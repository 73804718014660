import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TableForm from "../TableForm";

class UpdateUserInfoModal extends Component {
  render() {
    const { user } = this.props;
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader>
          Update UserInfo for <span className="text-primary">{user.name}</span>
        </ModalHeader>
        <ModalBody>
          <TableForm
            onSubmit={(e) => {
              this.props.onSubmit(e, user);
            }}
          >
            <tr>
              <td>First Name</td>
              <td>
                <input required type="text" className="form-control" name="firstName" defaultValue={user.firstName} />
              </td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>
                <input required type="text" className="form-control" name="lastName" defaultValue={user.lastName} />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <input type="submit" className="btn btn-info" defaultValue="Update" />
              </td>
            </tr>
          </TableForm>
        </ModalBody>
      </Modal>
    );
  }
}

export default UpdateUserInfoModal;
