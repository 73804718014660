import React from "react";
import { Input, Button } from "reactstrap";
import api from "../Services/ApiService";
import utils from "../Services/Utils";

class ChatQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strQuestion: "",
      lstQuestion: [],
      _id: "",
    };
  }
  componentDidMount() {
    this.loadChatQuestion();
  }

  loadChatQuestion = async () => {
    try {
      const res = await api.getChatQuestion();
      this.setState({ lstQuestion: res });
      // console.log(res)
    } catch (ex) {
      console.log(ex);
    }
  };

  onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await api.addChatQuestion(utils.addSlashes(this.state.strQuestion));
      this.loadChatQuestion();
      this.setState({ strQuestion: "" });
    } catch (ex) {
      console.log(ex);
    }
  };

  send = async (_id) => {
    try {
      await api.sendChatQuestion(_id);
      this.loadChatQuestion();
    } catch (ex) {
      console.log(ex);
    }
  };

  delete = async (_id) => {
    try {
      await api.removeChatQuestion(_id);
      this.loadChatQuestion();
    } catch (ex) {
      console.log(ex);
    }
  };

  clearAll = async () => {
    try {
      await api.clearAllQuestion();
      this.loadChatQuestion();
    } catch (ex) {
      console.log(ex);
    }
  };
  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Chat Question</h1>
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-10">
                <Input
                  value={this.state.strQuestion}
                  onChange={(e) => {
                    this.setState({ strQuestion: e.target.value });
                  }}
                  type="text"
                  name="text"
                  id="exampleText"
                />
              </div>
              <div className="col-2">
                <Button type="submit">Save</Button>
                <Button onClick={this.clearAll} color="danger">
                  Clear All
                </Button>
              </div>
            </div>
          </form>
        </div>
        <br />
        <br />

        <table className="table table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Question</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(
              this.state.lstQuestion.map((objQuestion, index) => {
                return (
                  <tr key={index.id}>
                    <td>{index + 1}</td>
                    <td>{objQuestion.questions}</td>
                    <td>{objQuestion.isSent?.toString()}</td>
                    <td>
                      <Button onClick={() => this.send(objQuestion._id)} color="primary">
                        Send
                      </Button>
                      <Button onClick={() => this.delete(objQuestion._id)} color="danger">
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              }),
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ChatQuestion;
