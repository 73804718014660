import React, { Component } from "react";

class TableForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.onSubmit}>
        <table className="table">
          <tbody>{this.props.children}</tbody>
        </table>
      </form>
    );
  }
}

export default TableForm;
