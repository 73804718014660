import lsu from "./LocalStorageUtils";

class AuthService {
  setToken(token) {
    lsu.lsSet("loginToken", token);
  }
  getToken() {
    return lsu.lsGet("loginToken");
  }

  setUserData(objUserData) {
    lsu.lsSet("userData", objUserData);
  }

  getUserData() {
    return lsu.lsGet("userData");
  }

  isLogged() {
    if (typeof this.getToken() === "string") {
      return true;
    }
    return false;
  }
}

const auth = new AuthService();
export default auth;
