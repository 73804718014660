import React, { Component } from "react";
import CommonModal from "../CommonModal";
import TableForm from "../TableForm";
import api from "../../Services/ApiService";
import utils from "../../Services/Utils";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  // CustomInput,
  FormGroup,
} from "reactstrap";
import enums from "../../../enums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const paymentObjectKeys = {
  transactionId: "",
  transactionDate: "",
  transactionAmount: "",
  paymentMethod: "",
};

class NewEnrollmentModal extends Component {
  state = {
    lstBootcamps: [],
    objBootcamps: {},
    selectedBootcamp: null,
    activeTab: "1",
    selectedCourseDate: "",
  };

  componentDidMount() {
    this.getBootcamps();
  }

  async getBootcamps() {
    try {
      const data = await api.getBootcamps();
      const { user } = this.props;
      if (data) {
        const lstBootcamps = utils.GroupObject(data);
        lstBootcamps.forEach((bootcamp) => {
          if (user.enrollments && user.enrollments[bootcamp.bootcampId]) {
            const userEnrollment = user.enrollments[bootcamp.bootcampId];
            for (const key in userEnrollment.bootcamp) {
              bootcamp[key] = userEnrollment.bootcamp[key];
            }

            bootcamp.progress = userEnrollment.progress;
            bootcamp.completed = userEnrollment.completed;

            for (const key in userEnrollment.certificate) {
              bootcamp["certificate." + key] = userEnrollment.certificate[key];
            }

            for (const key in userEnrollment.payment || paymentObjectKeys) {
              bootcamp["payment." + key] = userEnrollment.payment ? userEnrollment.payment[key] : "";
            }
          }
        });
        this.setState({ lstBootcamps, objBootcamps: data });
      }
    } catch (ex) {
      console.log("NewEnrollmentModal ", ex);
      alert(ex);
    }
  }

  onBootcampSelection(event) {
    const objBootcamp = utils.CopyObject(this.state.objBootcamps[event.target.value]);
    this.setState({ selectedBootcamp: objBootcamp });
  }

  async addNewEnrollment(event) {
    try {
      event.preventDefault();
      const selectedBootcamp = this.state.selectedBootcamp;

      const selectedCourse = this.state.selectedCourseDate.split("-");
      let selectedCourseObj = {};
      if (selectedCourse[0] === "LIVE") {
        selectedCourseObj = this.state.selectedBootcamp["live"][selectedCourse[1]];
        Object.keys(selectedCourseObj).forEach((key) => {
          selectedBootcamp[key] = selectedCourseObj[key];
        });
        selectedBootcamp.courseType = "Live";
      } else if (selectedCourse[0] === "ON_DEMAND") {
        selectedCourseObj = this.state.selectedBootcamp["onDemand"][selectedCourse[1]];
        Object.keys(selectedCourseObj).forEach((key) => {
          selectedBootcamp[key] = selectedCourseObj[key];
        });
        selectedBootcamp.courseType = "OnDemand";
        selectedBootcamp.endDate = moment(new Date(selectedBootcamp.startDate).getTime()).add(2, "months").format("MM/DD/YYYY");
      } else {
        return alert("Please select atleast one course");
      }

      delete selectedBootcamp.onDemand;
      delete selectedBootcamp.live;
      delete selectedBootcamp.value;
      let enrollmentDataJson = JSON.stringify(selectedBootcamp);
      enrollmentDataJson = utils.addSlashes(enrollmentDataJson);
      await api.updateEnrollment(this.state.selectedBootcamp.bootcampId, this.props.user.id, enrollmentDataJson);
      this.props.refreshUsers();
      this.props.toggle();
    } catch (ex) {
      alert("Error occured: ", ex);
    }
  }

  toggleTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { user } = this.props;
    console.log(this.state);
    return (
      <CommonModal isOpen={this.props.isOpen} toggle={this.props.toggle} header={"Add New Enrollment"}>
        <select onChange={this.onBootcampSelection.bind(this)} className="form-control">
          <option value="">Select Bootcamp</option>
          {this.state.lstBootcamps.map((bootcamp, index) => {
            if (!bootcamp) return null;

            if (user.enrollments && user.enrollments[bootcamp.bootcampId]) return null;
            // (
            //   <option key={index} value={bootcamp.bootcampId}>{`${bootcamp.title} (Exists)`}</option>
            // );

            return (
              <option key={index.id} value={bootcamp.bootcampId}>
                {bootcamp.title}
              </option>
            );
          })}
        </select>

        {this.state.selectedBootcamp && (
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this.toggleTab("1");
                }}
              >
                Basic
              </NavLink>
            </NavItem>
            {user.enrollments && user.enrollments[this.state.selectedBootcamp.bootcampId] && (
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  Payment Details
                </NavLink>
              </NavItem>
            )}
          </Nav>
        )}
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <TableForm onSubmit={this.addNewEnrollment.bind(this)}>
              {this.state.selectedBootcamp &&
                Object.keys(this.state.selectedBootcamp).map((key) => {
                  // console.log(this.state.selectedBootcamp)
                  const value = this.state.selectedBootcamp[key];
                  if (typeof value === "object" && value) {
                    // console.log(value)
                    value.forEach((item, index) => {
                      if (key === "live") item.value = "LIVE-" + index;
                      else if (key === "onDemand") item.value = "ON_DEMAND-" + index;
                    });
                  }
                  if (key === "__typename" || key.startsWith("payment.")) return null;
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        <FormGroup>
                          {typeof value === "object" && value ? (
                            value.map((item, index) => {
                              return (
                                <React.Fragment key={index.id}>
                                  <Row>
                                    <Col sm="1">
                                      <input
                                        type="radio"
                                        // id={"exampleCustomCheckbox" + item.value}
                                        name="radio"
                                        style={{ width: "25px", height: "25px" }}
                                        value={item.value}
                                        selected={item.value === this.state.selectedCourseDate ? true : false}
                                        onChange={(e) => {
                                          this.setState({ selectedCourseDate: e.target.value });
                                        }}
                                      />
                                    </Col>
                                    <Col sm="4">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name={key}
                                        onChange={(event) => {
                                          const val = event.target.value;
                                          this.setState((state) => {
                                            state.selectedBootcamp[key] = val;
                                            return state;
                                          });
                                        }}
                                        value={item.startDate || ""}
                                      />
                                    </Col>
                                    <Col sm="4">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name={key}
                                        onChange={(event) => {
                                          const val = event.target.value;
                                          this.setState((state) => {
                                            state.selectedBootcamp[key] = val;
                                            return state;
                                          });
                                        }}
                                        value={item.bcItem || ""}
                                      />
                                    </Col>
                                    <Col sm="3">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name={key}
                                        onChange={(event) => {
                                          const val = event.target.value;
                                          this.setState((state) => {
                                            state.selectedBootcamp[key] = val;
                                            return state;
                                          });
                                        }}
                                        value={item.CEU || ""}
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <input
                              className="form-control"
                              type="text"
                              name={key}
                              onChange={(event) => {
                                const val = event.target.value;
                                this.setState((state) => {
                                  state.selectedBootcamp[key] = val;
                                  return state;
                                });
                              }}
                              value={value || ""}
                            />
                          )}
                        </FormGroup>
                      </td>
                    </tr>
                  );
                })}
              {this.state.selectedBootcamp && (
                <tr>
                  <td></td>
                  <td>
                    <input className="btn btn-success" type="submit" defaultValue="Add" />
                  </td>
                </tr>
              )}
            </TableForm>
          </TabPane>
          <TabPane tabId="2">
            <TableForm onSubmit={this.addNewEnrollment.bind(this)}>
              {this.state.selectedBootcamp && (
                <React.Fragment>
                  <tr>
                    <td>Transaction Id</td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name={"payment.transactionId"}
                        onChange={(event) => {
                          const val = event.target.value;
                          this.setState((state) => {
                            state.selectedBootcamp["payment.transactionId"] = val;
                            return state;
                          });
                        }}
                        value={this.state.selectedBootcamp["payment.transactionId"] || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>
                      <DatePicker
                        className="form-control"
                        selected={this.state.selectedBootcamp["payment.transactionDate"] ? new Date(parseInt(this.state.selectedBootcamp["payment.transactionDate"])) : undefined}
                        onChange={(date) => {
                          this.setState((state) => {
                            state.selectedBootcamp["payment.transactionDate"] = date.getTime();
                            return state;
                          });
                        }}
                      />
                      {/* <input className="form-control" type="date" name={"payment.transactionDate"}
                        onChange={(event) => { let val = event.target.value; this.setState((state) => { state.selectedBootcamp["payment.transactionDate"] = new Date(val).getTime(); return state; }) }}
                        value={moment(parseInt(this.state.selectedBootcamp["payment.transactionDate"])).format("YYYY-MM-DD") || ""} /> */}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name={"payment.transactionAmount"}
                        onChange={(event) => {
                          const val = event.target.value;
                          this.setState((state) => {
                            state.selectedBootcamp["payment.transactionAmount"] = val;
                            return state;
                          });
                        }}
                        value={this.state.selectedBootcamp["payment.transactionAmount"] || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Method</td>
                    <td>
                      <select
                        className="form-control"
                        onChange={(event) => {
                          const val = event.target.value;
                          this.setState((state) => {
                            state.selectedBootcamp["payment.paymentMethod"] = val;
                            return state;
                          });
                        }}
                        value={this.state.selectedBootcamp["payment.paymentMethod"] || ""}
                      >
                        <option value="">Select Method</option>
                        {Object.keys(enums.PAYMENT_METHOD).map((paymentMethod) => {
                          return (
                            <option key={paymentMethod} value={paymentMethod}>
                              {paymentMethod}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                </React.Fragment>
              )}
              {this.state.selectedBootcamp && (
                <tr>
                  <td></td>
                  <td>
                    <input className="btn btn-success" type="submit" defaultValue="Add" />
                  </td>
                </tr>
              )}
            </TableForm>
          </TabPane>
        </TabContent>
      </CommonModal>
    );
  }
}

export default NewEnrollmentModal;
