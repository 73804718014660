//import gql from 'graphql-tag';
import gqService from "../GraphQL/GraphQLService";
import gqFragments from "../GraphQL/Fragments";
import utils from "../Utils";

class ApiService {
  async getBootcamps() {
    const response = await gqService.graphQLQuery(
      `query {
        bootcamps{
          cloud{
            ... BootcampItemFragment
          }
          mlai{
            ... BootcampItemFragment
          }
          cloudworkshop{
            ... BootcampItemFragment
          }
          tcs{
            ... BootcampItemFragment
          }
          fintech{
            ... BootcampItemFragment
          }
        }
      }
      ${gqFragments.BootcampItemFragment}
      `,
    );
    return response.data.bootcamps;
  }

  async getuserData() {
    const response = await gqService.graphQLQuery(
      `query {
      user{
          name,
          role,
          email,
          username
        }
      }
      `,
    );
    return response.data;
  }

  async getAllUsers(search) {
    const { role, course } = search;
    const response = await gqService.graphQLQuery(`query {
        getAllUsers(role:"${role || ""}", course:"${course || ""}"){
          ... UserAllInfoFragment
        }
      }
      ${gqFragments.UserAllInfoFragment}
      `);
    //console.log(response.data.getAllUsers[0].enrollments.tcs.bootcamp.title,"sadf");
    return response.data.getAllUsers;
  }

  async errorLog(errorId) {
    const response = await gqService.graphQLQuery(
      `query{
          errorLog(errorId:"${errorId || ""}"){
            _id
            message
            time
          }
        }
      `,
    );
    //console.log(response.data.getAllUsers[0].enrollments.tcs.bootcamp.title,"sadf");
    return response.data.errorLog;
  }

  async errorLogInfo(errorId) {
    const response = await gqService.graphQLQuery(
      `query{
          errorLog(errorId:"${errorId || ""}"){
            _id
            json
          }
        }
      `,
    );
    //console.log(response.data.getAllUsers[0].enrollments.tcs.bootcamp.title,"sadf");
    return response.data.errorLog;
  }

  async mauticGetContacts(noCache = false, havingEmailOnly = false) {
    // console.log("mauticGetContacts");
    const response = await gqService.graphQLQuery(
      `query{
        mauticGetContacts(noCache:${noCache},havingEmailOnly:${havingEmailOnly})
      }
      `,
    );
    return JSON.parse(response.data.mauticGetContacts);
  }

  mauticGetContactsWithCache = utils.withCache(this.mauticGetContacts, "mauticGetContacts", 60);

  async mauticGetTags() {
    const response = await gqService.graphQLQuery(
      `query{
        mauticGetTags
      }
      `,
    );
    return JSON.parse(response.data.mauticGetTags);
  }

  mauticGetTagsWithCache = utils.withCache(this.mauticGetTags, "mauticGetTags", 60);

  async getScheduleAdmin() {
    const response = await gqService.graphQLQuery(
      `query{
        getScheduleAdmin{
          _id
          scheduleDate
          title
          description
          ready
          file
          vimeoLink
          scheduleType
          scheduleDay
          scheduleTime
          totalOccurrence
          repeatEvery
        }
      }
      `,
    );

    return response.data.getScheduleAdmin;
  }

  async getZoomRecordings() {
    const response = await gqService.graphQLQuery(
      `query{
        getZoomRecordings
      }
      `,
    );

    return response.data.getZoomRecordings;
  }

  async getDbConfig(fields) {
    const response = await gqService.graphQLQuery(
      `query{
        getDbConfig{
          ${fields.join("\n")}
        }
      }
      `,
    );

    return response.data.getDbConfig;
  }
  async getSettings() {
    const response = await gqService.graphQLQuery(
      `query{
        getSettings
      }
      `,
    );

    return JSON.parse(response.data.getSettings);
  }

  async createCertificate(bootcampId, userId) {
    //console.log(bootcampId, userId);
    const response = await gqService.graphQLMutate(
      `mutation{
        createCertificate(bootcampId:"${bootcampId}",userId:"${userId}")
      }
      `,
    );
    return response.data.createCertificate;
  }

  async changeEnrollmentCourseName(bootcampId, userId, newCourseName) {
    const response = await gqService.graphQLMutate(
      `mutation{
        changeEnrollmentCourseName(bootcampId:"${bootcampId}",userId:"${userId}",newCourseName:"${newCourseName}")
      }
      `,
    );
    return response.data.changeEnrollmentCourseName;
  }

  async markCourseComplete(bootcampId, userId) {
    //console.log(bootcampId, userId);
    const response = await gqService.graphQLMutate(
      `mutation{
        markCourseComplete(bootcampId:"${bootcampId}",userId:"${userId}")
      }
      `,
    );
    return response.data.markCourseComplete;
  }

  async updateUserName(firstName, lastName, userId) {
    //console.log(bootcampId, userId);
    const response = await gqService.graphQLMutate(
      `mutation{
        updateUserName(
          firstName:"${firstName}",
          lastName:"${lastName}",
          userId:"${userId}"
        )
      }
      `,
    );
    return response.data.updateUserName;
  }

  async updateEnrollment(bootcampId, userId, enrollmentDataJson) {
    //console.log(bootcampId, userId);
    const response = await gqService.graphQLMutate(
      `mutation{
        updateEnrollment(
          bootcampId:"${bootcampId}",
          userId:"${userId}",
          enrollmentDataJson:"${enrollmentDataJson}"
        )
      }
      `,
    );
    return response.data.updateEnrollment;
  }

  async emailCredential(certificateId, userId) {
    const response = await gqService.graphQLMutate(
      `mutation{
        emailCredential(
          certificateId: "${certificateId}"
          userId:"${userId}"
        )
      }
      `,
    );
    return response.data.emailCredential;
  }

  async updateUserRole(userId, aryRoles) {
    let aryRolesJSON = JSON.stringify(aryRoles);
    aryRolesJSON = utils.addSlashes(aryRolesJSON);
    const response = await gqService.graphQLMutate(
      `mutation{
        updateUserRole(
          userId:"${userId}"
          aryRolesJSON: "${aryRolesJSON}"
        )
      }
      `,
    );
    return response.data.updateUserRole;
  }

  async mauticEditContact(contactId, firstName, lastName, mobile, points, tags) {
    const response = await gqService.graphQLMutate(
      `mutation{
        mauticEditContact(
          contactId:"${contactId}",
          firstName:"${firstName}",
          lastName:"${lastName}",
          mobile:"${mobile}",
          points:"${points}",
          tags:"${tags}"
        )
      }
      `,
    );
    return response.data.mauticEditContact;
  }

  async mauticRemoveFromDNC(contactId) {
    const response = await gqService.graphQLMutate(
      `mutation{
        mauticRemoveFromDNC(
          contactId:"${contactId}"
        )
      }
      `,
    );
    return response.data.mauticRemoveFromDNC;
  }

  async mauticAddDNC(contactId) {
    const response = await gqService.graphQLMutate(
      `mutation{
        mauticAddDNC(
          contactId:"${contactId}"
        )
      }
      `,
    );
    return response.data.mauticAddDNC;
  }

  async getUserApplicationList(searchText, interviewSort) {
    const response = await gqService.graphQLQuery(
      `query{
        getUserApplicationList(
            searchText:"${searchText}",
            interviewSort:"${interviewSort}"
        ){
          applicationId
          userId
          firstName
          lastName
          email
          dob
          interestedInCourses
          linkedInLink
          whyJoin
          codingHurdlePassed
          lastStepPerformed
          isApplicationCompleted
        }
      }
      `,
    );
    return response.data.getUserApplicationList;
  }

  async changeInterviewStatus(interviewStatus, applicationId) {
    const response = await gqService.graphQLMutate(
      `mutation{
        changeInterviewStatus(
          codingHurdlePassed:${interviewStatus}
          applicationId:"${applicationId}"
        )
      }
      `,
    );
    return JSON.parse(response.data.changeInterviewStatus);
  }
  async deleteUserApplication(applicationId) {
    const response = await gqService.graphQLMutate(
      `mutation{
        deleteUserApplication(
          applicationId:"${applicationId}"
        )
      }
      `,
    );
    return JSON.parse(response.data.deleteUserApplication);
  }

  async setLastVisitUrlOfAllUsers(pathname) {
    const response = await gqService.graphQLMutate(
      `mutation{
        setLastVisitUrlOfAllUsers(
          pathname:"${pathname}"
        )
      }
      `,
    );
    return response.data.setLastVisitUrlOfAllUsers;
  }

  async addSchedule(scheduleDateISOString, title, description, file, ready, _id, scheduleType, scheduleDay, scheduleTime, totalOccurrence, repeatEvery) {
    const response = await gqService.graphQLMutate(
      `mutation{
        addSchedule(
          scheduleDateISOString:"${utils.addSlashes(scheduleDateISOString)}"
          title:"${utils.addSlashes(title)}"
          description:"${btoa(description)}"
          file:"${utils.addSlashes(file)}"
          ready:${ready ? "true" : "false"}
          _id:"${utils.addSlashes(_id)}"
          scheduleType:"${utils.addSlashes(scheduleType)}"
          scheduleDay:"${scheduleDay}"
          scheduleTime:"${utils.addSlashes(scheduleTime)}"
          totalOccurrence:"${totalOccurrence}"
          repeatEvery:${repeatEvery}
        )
      }
      `,
    );
    return response.data.addSchedule;
  }

  async deleteSchedule(_id) {
    const response = await gqService.graphQLMutate(
      `mutation{
        deleteSchedule(
          _id:"${_id}"
        )
      }
      `,
    );
    return response.data.deleteSchedule;
  }

  async downloadZoomRecording(json) {
    const response = await gqService.graphQLMutate(
      `mutation{
        downloadZoomRecording(
          json:${JSON.stringify(json)}
        )
      }
      `,
    );
    return response.data.downloadZoomRecording;
  }

  async setDbConfig(key, value, type = "STRING") {
    const response = await gqService.graphQLMutate(
      `mutation{
        setDbConfig(
          key:"${key}",
          value:"${value}",
          type:"${type}",
        )
      }
      `,
    );
    return response.data.setDbConfig;
  }

  async setChatQuestion(strQuestion, _id) {
    const response = await gqService.graphQLMutate(
      `mutation{
        setChatQuestion(
          strQuestion:"${strQuestion}",
          _id:"${_id}"
        )
      }
      `,
    );
    return response.data.setChatQuestion;
  }

  async addChatQuestion(strQuestion) {
    const response = await gqService.graphQLMutate(
      `mutation{
        addChatQuestion(
          strQuestion:"${strQuestion}",
        )
      }
      `,
    );
    return response.data.addChatQuestion;
  }

  async sendChatQuestion(_id) {
    const response = await gqService.graphQLMutate(
      `mutation{
        sendChatQuestion(
          _id:"${_id}",
        )
      }
      `,
    );
    return response.data.sendChatQuestion;
  }

  async removeChatQuestion(_id) {
    const response = await gqService.graphQLMutate(
      `mutation{
        removeChatQuestion(
          _id:"${_id}",
        )
      }
      `,
    );
    return response.data.removeChatQuestion;
  }

  async clearAllQuestion() {
    const response = await gqService.graphQLMutate(
      `mutation{
        clearAllQuestion
      }
      `,
    );
    return response.data.clearAllQuestion;
  }

  async getChatQuestion() {
    const response = await gqService.graphQLMutate(
      `query{
        getChatQuestion
      }
      `,
    );
    return JSON.parse(response.data.getChatQuestion);
  }
}

const api = new ApiService();
window.api = api;
export default api;
