import React from "react";
import api from "../Services/ApiService";
import { Row, Col, Button } from "reactstrap";

export default class Home extends React.Component {
  state = {
    pathname: "",
  };

  async setLastVisitedUrlOfAllUsers() {
    if (!this.state.pathname) return alert("please fill pathname");
    if (!window.confirm("Are you sure? ")) return;
    try {
      const resp = await api.setLastVisitUrlOfAllUsers(this.state.pathname);
      alert(resp);
    } catch (ex) {
      alert(ex.message);
    }
  }

  render() {
    return (
      <div className="container">
        <h3>Home Page</h3>
        <Row className="spacing-2">
          <Col className="col" xs="12" lg="4" style={{ margin: "auto" }}>
            <span>Set last visited url of all users, e.g. /ai</span>
          </Col>
          <Col xs="12" sm="9" lg="6" className="col">
            <input
              type="text"
              className="form-control"
              value={this.state.pathname}
              onChange={(e) => {
                this.setState({ pathname: e.target.value });
              }}
              placeholder="pathname"
            />
          </Col>
          <Col xs="12" sm="3" lg="2" className="col">
            <Button style={{ width: "100%" }} color="primary" onClick={this.setLastVisitedUrlOfAllUsers.bind(this)}>
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
