import React from "react";
import "./css/bootstrap.css";
import Header from "./App/Header";
import { BrowserRouter, Route } from "react-router-dom";
import "./css/style.css";
import "./css/scrollbar.css";
import "./css/common.css";
import "./css/responsive.css";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Route path="/" component={Header} />
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
