import React from "react";
import { Input, CustomInput, Button } from "reactstrap";
import api from "../Services/ApiService";

export default class UserApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userApplicationList: null,
      searchText: "",
      sortValue: "",
    };
  }

  async componentDidMount() {
    this.getUserApplicationList();
  }
  getUserApplicationList = async () => {
    try {
      var userApplications = await api.getUserApplicationList(this.state.searchText, this.state.sortValue);
      this.setState({ userApplicationList: userApplications });
    } catch (ex) {
      console.log(ex);
    }
  };
  onChangeInterviewStatus = async (status, applicationId) => {
    try {
      var changedApplication = await api.changeInterviewStatus(status, applicationId);

      var updatedState = this.state.userApplicationList.map((userItem) => {
        let item = userItem;
        if (item.applicationId === applicationId) item = changedApplication;
        return item;
      });
      this.setState({ userApplicationList: updatedState });
    } catch (ex) {
      console.log(ex);
    }
  };

  onDeleteUserApplication = async (userApplication) => {
    try {
      await api.deleteUserApplication(userApplication.applicationId);
      this.getUserApplicationList();
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    return (
      <div style={{ padding: 50 }}>
        <div className="container-fluid" style={{ marginBottom: 25 }}>
          <div className="row">
            <div className="col-4">
              <h1>Users Application</h1>
            </div>
            <div className="col-4">
              <Input
                placeholder="search "
                type="search"
                onChange={(e) => {
                  this.setState({ searchText: e.target.value });
                }}
              />
              <Button onClick={this.getUserApplicationList} color="primary">
                Search
              </Button>
            </div>

            <div className="col-4">
              <CustomInput
                value={this.state.sortValue}
                type="select"
                id="sortSelect"
                name="sort"
                onChange={(e) => {
                  this.setState({ sortValue: e.target.value });
                }}
              >
                <option value="">Sort By Interview Pass/Fail</option>
                <option value={true}>Pass</option>
                <option value={false}>Fail</option>
              </CustomInput>
              <Button onClick={this.getUserApplicationList} color="primary">
                Sort
              </Button>
            </div>
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>FirstName</th>
              <th>LastName</th>
              <th>Email</th>
              <th>DOB</th>
              <th>Interest In Course</th>
              <th>LinkedIn Link</th>
              <th>Why Choose?</th>
              <th>Application Process</th>
              <th>Interview Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.userApplicationList?.map((applicationItem, index) => {
              return (
                <tr key={index.id}>
                  <td>{applicationItem.firstName}</td>
                  <td>{applicationItem.lastName}</td>
                  <td>{applicationItem.email}</td>
                  <td>{applicationItem.dob}</td>
                  <td>{applicationItem.interestedInCourses}</td>
                  <td>{applicationItem.linkedInLink}</td>
                  <td>{applicationItem.whyJoin}</td>
                  <td>{applicationItem.isApplicationCompleted ? "Completed" : "Pending"}</td>
                  <td style={{ textAlign: "center" }}>
                    <CustomInput
                      type="switch"
                      id={`exampleCustomSwitch${index}`}
                      name="interviewResultSwitch"
                      bsSize="lg"
                      checked={!!applicationItem.codingHurdlePassed}
                      onChange={() => {
                        this.onChangeInterviewStatus(!applicationItem.codingHurdlePassed, applicationItem.applicationId);
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.onDeleteUserApplication(applicationItem);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
