require("dotenv").config();

class Config {
  constructor() {
    if (process.env.NODE_ENV === "production") {
      this.BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
      this.FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
      this.APP_ROOT_URL = process.env.REACT_APP_ADMIN_URL;
    }
  }
  BACKEND_URL = "https://backend.home.cloudgeni.us";
  FRONTEND_URL = "https://homefrontend.cloudgenius.app";
  APP_ROOT_URL = "https://homeadmin.cloudgenius.app";
}

const config = new Config();
export default config;
