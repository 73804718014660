import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../../css/loading.css";

export default class Loading extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="divLoading">
          <FontAwesomeIcon className="spinner" spin icon={faSpinner} />
        </div>
        <div id="divLoadingSmall">
          <FontAwesomeIcon className="spinner" spin icon={faSpinner} />
        </div>
      </React.Fragment>
    );
  }
}
