import React, { Component } from "react";
import moment from "moment";
import CommonModal from "../../Components/CommonModal";
import { Table, Button, CustomInput, Row, Col } from "reactstrap";
import renderUtils from "../../Services/RenderUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enums from "../../../enums";

class AddScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleDate: "",
      title: "",
      description: "",
      file: "",
      _id: "",
      ready: true,
      totalOccurrence: "",
      repeatEvery: "1",
      scheduleDay: enums.SCHEDULE_DAY_TYPE.MONDAY,
      scheduleTime: "",
      scheduleType: enums.SCHEDULE_TYPE.ONE_TIME,
    };

    if (this.props.data) {
      this.state = {
        ...this.state,
        scheduleDate: this.props.data.scheduleDate ? moment(this.props.data.scheduleDate).toDate() : "",
        title: this.props.data.title || "",
        description: this.props.data.description || "",
        file: this.props.data.file || "",
        ready: this.props.data.ready || "",
        _id: this.props.data._id || "",
        totalOccurrence: this.props.data.totalOccurrence || "",
        repeatEvery: this.props.data.repeatEvery || "1",
        scheduleDay: this.props.data.scheduleDay || enums.SCHEDULE_DAY_TYPE.MONDAY,
        scheduleTime: this.props.data.scheduleTime ? moment(this.props.data.scheduleTime).toDate() : "",
        scheduleType: this.props.data.scheduleType || enums.SCHEDULE_TYPE.ONE_TIME,
      };
    }
  }

  validate() {
    const paramObj = { ...this.state };

    for (const item of ["title", "description", "file"]) {
      if (!this.state[item]) return alert("Select " + item);
    }
    if (this.state.scheduleType === enums.SCHEDULE_TYPE.ONE_TIME && !this.state.scheduleDate) return alert("Select Time");

    if (this.state.scheduleType === enums.SCHEDULE_TYPE.REPEAT) {
      for (const item of ["scheduleDay", "scheduleTime", "totalOccurrence"]) {
        if (!this.state[item]) return alert("Please Enter " + item);
      }
      paramObj.scheduleTime = this.state.scheduleTime.toISOString();
      paramObj.scheduleDay = this.state.scheduleDay.toString();
      paramObj.totalOccurrence = this.state.totalOccurrence.toString();
    } else {
      paramObj.scheduleTime = "";
      paramObj.scheduleDay = "";
      paramObj.totalOccurrence = "";
    }
    paramObj.scheduleDate = this.state.scheduleDate.toISOString();
    console.log(paramObj);
    this.props.onSubmit(paramObj);
  }

  render() {
    return (
      <CommonModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        header={
          <span>
            Add/Edit Schedule <span className="text-primary"></span>
          </span>
        }
      >
        <Table striped>
          <tbody>
            <tr>
              <td>Schedule Type</td>
              <td>
                <CustomInput
                  value={this.state.scheduleType}
                  type="select"
                  id="scheduleType"
                  name="scheduleType"
                  onChange={(e) => {
                    this.setState({ scheduleType: e.target.value });
                  }}
                >
                  <option value={enums.SCHEDULE_TYPE.ONE_TIME}>One Time</option>
                  <option value={enums.SCHEDULE_TYPE.REPEAT}>Repeat</option>
                </CustomInput>
              </td>
            </tr>

            <tr>
              <td>Title</td>
              <td>{renderUtils.renderTextField(this, "title", "Title")}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{renderUtils.renderTextField(this, "description", "Description", { type: "textarea" })}</td>
            </tr>
            <tr>
              <td>File Name</td>
              <td>{renderUtils.renderTextField(this, "file", "File")}</td>
            </tr>
            <tr>
              <td>Ready</td>
              <td>{renderUtils.renderCheckbox(this, "ready", "Ready")}</td>
            </tr>
            {/* {this.state.scheduleType === enums.SCHEDULE_TYPE.ONE_TIME && */}
            <tr>
              <td>Schedule/Start Date</td>
              <td>
                <DatePicker
                  className="form-control"
                  selected={this.state.scheduleDate}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  minDate={new Date()}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={(date) => {
                    this.setState((state) => {
                      state.scheduleDate = date;
                      return state;
                    });
                  }}
                />
              </td>
            </tr>
            {/* // } */}
            {this.state.scheduleType === enums.SCHEDULE_TYPE.REPEAT && (
              <React.Fragment>
                {/* <tr>
                                    <td>Start Date</td>
                                    <td>
                                        <DatePicker
                                            className="form-control" selected={this.state.scheduleStartDate}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            minDate={new Date()}
                                            timeIntervals={10}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(date) => { this.setState((state) => { state.scheduleStartDate = date; return state; }) }}
                                        />
                                    </td>
                                </tr> */}
                <tr>
                  <td>Schedule Day</td>
                  <td>
                    <CustomInput
                      value={this.state.scheduleDay}
                      type="select"
                      id="scheduleDay"
                      name="scheduleDay"
                      onChange={(e) => {
                        this.setState({ scheduleDay: e.target.value });
                      }}
                    >
                      {Object.keys(enums.SCHEDULE_DAY_TYPE).map((day) => {
                        return (
                          <option key={day} value={enums.SCHEDULE_DAY_TYPE[day]}>
                            {day}
                          </option>
                        );
                      })}
                    </CustomInput>
                  </td>
                </tr>
                <tr>
                  <td>Repeat Every</td>
                  <td>
                    <Row>
                      <Col>{renderUtils.renderTextField(this, "repeatEvery", "N Week", { type: "number", min: "1" })}</Col>
                      <Col sm={2}> Week(s)</Col>
                      <Col sm={1}> </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td>Schedule Time</td>
                  <td>
                    <DatePicker
                      className="form-control"
                      selected={this.state.scheduleTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      timeCaption="Time"
                      showTimeSelectOnly
                      dateFormat="h:mm aa"
                      onChange={(date) => {
                        this.setState({ scheduleTime: date });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total Occurrence</td>
                  <td>{renderUtils.renderTextField(this, "totalOccurrence", "Total Occurrence", { type: "number" })}</td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <td></td>
              <td>
                <Button
                  color="primary"
                  onClick={() => {
                    this.validate();
                  }}
                >
                  {this.state._id ? "Update" : "Add"}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </CommonModal>
    );
  }
}

export default AddScheduleModal;
