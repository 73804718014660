import React, { Component } from "react";
import CommonModal from "../CommonModal";
import TableForm from "../TableForm";
import enums from "../../../enums";
import utils from "../../Services/Utils";
import api from "../../Services/ApiService";

class EditRoleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: props.user && props.user.role,
    };
    this.roles = Object.keys(enums.USER_ROLES).map((key) => {
      return enums.USER_ROLES[key];
    });
  }

  async onUserEditFormSubmit(event) {
    try {
      event.preventDefault();
      await api.updateUserRole(this.props.user.id, this.props.user.role);
      this.props.refreshUsers();
      this.props.toggle();
    } catch (ex) {
      alert(ex);
    }
  }

  onUserRoleChange(e, role) {
    const { user } = this.props;
    const checked = e.target.checked;
    if (checked) {
      if (user.role.indexOf(role) < 0) {
        user.role.push(role);
      }
    } else {
      if (user.role.indexOf(role) >= 0) {
        utils.removeFromArray(user.role, role);
      }
    }
    this.setState({});
  }

  render() {
    const { user } = this.props;
    const userRoles = user.role || [];
    return (
      <CommonModal isOpen={this.props.isOpen} toggle={this.props.toggle} header={"Edit User Roles"}>
        <TableForm onSubmit={this.onUserEditFormSubmit.bind(this)}>
          {this.roles.map((role, index) => {
            return (
              <tr key={index.id}>
                <td>{role}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={userRoles.indexOf(role) >= 0}
                    onChange={(event) => {
                      this.onUserRoleChange(event, role);
                    }}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td>
              <button className="btn btn-primary" type="submit">
                Update
              </button>
            </td>
          </tr>
        </TableForm>
      </CommonModal>
    );
  }
}

export default EditRoleModal;
