import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import enums from "../../enums";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.roles = Object.keys(enums.USER_ROLES).map((key) => {
      return enums.USER_ROLES[key];
    });
  }

  render() {
    return (
      <div>
        Click To View Users.
        {this.roles.map((role) => (
          <div key={role} style={{ textAlign: "center" }}>
            <NavLink className="btn btn-info" to={`/users/role/${role}`}>
              {role.toUpperCase()}
            </NavLink>
            <br></br>
            <br></br>
          </div>
        ))}
      </div>
    );
  }
}

export default Roles;
