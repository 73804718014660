import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import config from "../../../config";
import api from "../../Services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import utils from "../../Services/Utils";
import NewEnrollmentModal from "./NewEnrollmentModal";
import EditEnrollmentModal from "./EditEnrollmentModal";

class EnrollmentInfoModal extends Component {
  state = {
    isEditingCourseName: false,
    isNewEnrollmentModalOpen: false,
    isEditEnrollmentModalOpen: false,
    selectedEditEnrollment: undefined,
  };

  editBootcampName() {
    this.setState({ isEditingCourseName: true });
  }

  async updateBootcampName(user, enrollment) {
    try {
      await api.changeEnrollmentCourseName(enrollment.bootcamp.bootcampId, user.id, enrollment.bootcamp.title);
      // console.log("course name updated now creating cert");
      // let certificateUrl = await api.createCertificate(enrollment.bootcamp.bootcampId, user.id);
      alert(`bootcamp name Updated. `);
    } catch (ex) {
      alert(ex);
    }
    this.setState({ isEditingCourseName: false });
  }

  async reGenerateCertificate(user, enrollment) {
    try {
      const certificateUrl = await api.createCertificate(enrollment.bootcamp.bootcampId, user.id);
      alert(`Certificate Uploaded ${certificateUrl}`);
      this.props.refreshUsers();
    } catch (ex) {
      alert(ex);
    }
  }

  async markCourseAsComplete(user, enrollment) {
    try {
      await api.markCourseComplete(enrollment.bootcamp.bootcampId, user.id);
      // console.log("Course is marked completed now re generating cert. ");
      // let certificateUrl = await api.createCertificate(enrollment.bootcamp.bootcampId, user.id);
      alert(`Course Marked Completed. `);
      this.props.refreshUsers();
    } catch (ex) {
      alert(ex);
    }
  }

  async emailCredential(user, enrollment) {
    try {
      if (!enrollment.certificate) {
        return alert("Certificate is not created yet. ");
      }
      await api.emailCredential(enrollment.certificate.id, user.id);
      alert(`Email Sent. `);
      this.props.refreshUsers();
    } catch (ex) {
      alert(ex);
    }
  }

  async editEnrollment(enrollment) {
    this.setState({ isEditEnrollmentModalOpen: true, selectedEditEnrollment: enrollment });
  }

  toggleNewEnrollmentModal = () => {
    this.setState({ isNewEnrollmentModalOpen: !this.state.isNewEnrollmentModalOpen });
  };
  toggleEditEnrollmentModal = () => {
    this.setState({ isEditEnrollmentModalOpen: !this.state.isEditEnrollmentModalOpen });
  };

  addNewEnrollment() {
    //console.log("working", this.state.isNewEnrollmentModalOpen);
    this.setState({ isNewEnrollmentModalOpen: true });
  }

  render() {
    const { user } = this.props;
    console.log(this.props, this.state);
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader>
          Enrollments of <span className="text-primary">{user.name}</span>
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "right" }}>
            <button className="btn btn-success" onClick={this.addNewEnrollment.bind(this)}>
              New Enrollment
            </button>
          </div>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Progress</th>
                <th>Certificate</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {user.lstEnrollments &&
                user.lstEnrollments.map((enrollment, index) => {
                  console.log(enrollment);
                  return (
                    <tr key={index.id}>
                      <td>
                        {/* {this.state.isEditingCourseName ?
                        <React.Fragment>
                          <input type="text" className="form-control" value={enrollment.bootcamp.title} onChange={(e) => { enrollment.bootcamp.title = e.target.value; this.setState({}) }} />
                          &nbsp;
                            <button className="btn btn-primary btn-sm" title="Edit Course Name. " onClick={this.updateBootcampName.bind(this, user, enrollment)}>
                            <i className="fa fa-check"></i>
                          </button>
                        </React.Fragment>
                        :
                        <React.Fragment> */}
                        {enrollment.bootcamp.title}
                        {/* &nbsp;
                            <button className="btn btn-primary btn-sm" title="Update Course name. " onClick={this.editBootcampName.bind(this, user, enrollment)}>
                            <i className="fa fa-edit"></i>
                          </button>
                        </React.Fragment>
                      } */}
                      </td>
                      <td>{enrollment.bootcamp.startDate}</td>
                      <td>{enrollment.bootcamp.endDate}</td>
                      <td>{utils.toFixed(enrollment.progress, 2)}%</td>
                      <td>
                        {enrollment.certificate ? (
                          <React.Fragment>
                            <a href={`${config.FRONTEND_URL}/certificate/${enrollment.certificate.id}`} target="_blank" rel="noopener noreferrer">
                              View
                            </a>
                          </React.Fragment>
                        ) : (
                          <span>Not Created</span>
                        )}
                        &nbsp;
                        <button className="btn btn-primary btn-sm" title="Re generate certificate. " onClick={this.reGenerateCertificate.bind(this, user, enrollment)}>
                          <FontAwesomeIcon icon={faSync} />
                        </button>
                      </td>
                      <td>
                        {!enrollment.completed && (
                          <button className="btn btn-info btn-sm" onClick={this.markCourseAsComplete.bind(this, user, enrollment)}>
                            Mark Completed
                          </button>
                        )}
                        {enrollment.certificate && (
                          <button className="btn btn-info btn-sm" onClick={this.emailCredential.bind(this, user, enrollment)}>
                            Email Cred
                          </button>
                        )}
                        <button className="btn btn-primary btn-sm" onClick={this.editEnrollment.bind(this, enrollment)}>
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.props.toggle}>
            Close
          </button>
        </ModalFooter>
        {this.state.isNewEnrollmentModalOpen && <NewEnrollmentModal isOpen={this.state.isNewEnrollmentModalOpen} toggle={this.toggleNewEnrollmentModal} user={user} refreshUsers={this.props.refreshUsers}></NewEnrollmentModal>}
        {this.state.isEditEnrollmentModalOpen && (
          <EditEnrollmentModal isOpen={this.state.isEditEnrollmentModalOpen} toggle={this.toggleEditEnrollmentModal} user={user} refreshUsers={this.props.refreshUsers} enrollment={this.state.selectedEditEnrollment}></EditEnrollmentModal>
        )}
      </Modal>
    );
  }
}

export default EnrollmentInfoModal;
