//import gql from 'graphql-tag';
class Fragments {
  EnrollmentItemFragment = `fragment EnrollmentItemFragment on EnrollmentItem {
    bootcamp{
      title
      startDate
      endDate
      courseId
      bootcampId
    }
    id
    progress
    completed
    certificate{
      id
      issuedDate
      expireDate
      url
    }
    payment{
      transactionId
      transactionDate
      transactionAmount
      paymentMethod
    }
  }`;

  EnrollmentsFragment = `fragment EnrollmentsFragment on Enrollments {
      mlai {
        ...EnrollmentItemFragment
      }
      cloud {
        ...EnrollmentItemFragment
      }
      cloudworkshop {
        ...EnrollmentItemFragment
      }
      fintech {
        ...EnrollmentItemFragment
      }
      tcs {
        ...EnrollmentItemFragment
      }
    }
    ${this.EnrollmentItemFragment}
    `;

  UserAllInfoFragment = `fragment UserAllInfoFragment on User {
    name
    username
    image
    email
    role
    id
    firstName
    lastName
    enrollments {
      ...EnrollmentsFragment
    }
  }
    ${this.EnrollmentsFragment}
  `;

  BootcampItemFragment = `fragment BootcampItemFragment on BootcampItem {
    title
    courseId
    price
    bootcampId
    live{
      startDate
      timeZoneString
      startTime
      freq
      endDate
      endTime
      bcItem
      CEU
    }
    onDemand{
      startDate
      bcItem
      CEU
    }
  }`;

  UserApplicationItemFragment = `fragment UserApplicationItemFragment on UserApplication {
    applicationId
    userId
    firstName
    lastName
    email
    dob
    interestedInCourses
    linkedInLink
    whyJoin
    codingHurdlePassed
    lastStepPerformed
    isApplicationCompleted
  }`;
}

const gqFragments = new Fragments();
export default gqFragments;
