import config from "../../../config";
import auth from "../AuthService";
import utils from "../Utils";

class GraphQLServiceFetch {
  graphQLQuery = async (gqlQuery) => {
    try {
      utils.showLoading();
      const rawResponse = await fetch(config.BACKEND_URL + "/graphql?loginToken=" + auth.getToken(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: gqlQuery }),
      });
      const result = await rawResponse.json();
      if (result.errors) {
        // console.log(result.errors[0].message)
        throw Error(result.errors[0].message);
      }
      utils.hideLoading();
      return result;
    } catch (ex) {
      utils.hideLoading();
      throw ex;
    }
  };

  graphQLMutate = async (gqlMutate) => {
    try {
      utils.showLoading();
      const rawResponse = await fetch(config.BACKEND_URL + "/graphql?loginToken=" + auth.getToken(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: gqlMutate }),
      });
      const result = await rawResponse.json();
      if (result.errors) {
        throw Error(result.errors[0].message);
      }
      utils.hideLoading();
      return result;
    } catch (ex) {
      utils.hideLoading();
      throw ex;
    }
  };
}

const gqService = new GraphQLServiceFetch();
export default gqService;
