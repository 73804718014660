import React from "react";
import api from "../Services/ApiService";
import utils from "../Services/Utils";
import EnrollmentInfoModal from "../Components/Users/EnrollmentInfoModal";
import Loading from "../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import UpdateUserInfoModal from "../Components/Users/UpdateUserInfoModal";
import EditRoleModal from "../Components/Users/EditRoleModal";
import Select from "react-select";
import enums from "../../enums";
import { Input, Row, Col } from "reactstrap";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lstUsers: [],
      lstTempUsers: [],
      selectedUser: null,
      isEnrollmentModalOpen: false,
      isUpdateUserInfoModalOpen: false,
      isEditRoleModalOpen: false,
      enrollmentFilterValue: [],
      roleFilterValue: [],
    };
    this.allEnrollments = [];
    this.roles = Object.keys(enums.USER_ROLES).map((key) => {
      return { value: enums.USER_ROLES[key], label: key };
    });
  }

  async componentDidMount() {
    const bootcamps = await api.getBootcamps();
    this.allEnrollments = Object.keys(bootcamps).map((bootcampId) => {
      return { value: bootcampId, label: bootcamps[bootcampId].title };
    });
    this.getUsers();
  }

  getUsers = async () => {
    try {
      let users = await api.getAllUsers({ role: this.props.match.params.role });
      //console.log(users);
      users = users.map((user) => {
        user.lstEnrollments = utils.GroupObject(user.enrollments || {});
        user.lstEnrollments = utils.TrimArray(user.lstEnrollments);
        return user;
      });
      let selectedUser = null;
      if (this.state.selectedUser) {
        selectedUser = users.find((user) => user.id === this.state.selectedUser.id);
      }
      this.setState({ lstUsers: users, selectedUser, lstTempUsers: users });
    } catch (ex) {
      console.log(ex);
      alert("Error occured while getting users. " + ex);
    }
  };

  toggleEnrollmentModal = () => {
    this.setState({ isEnrollmentModalOpen: !this.state.isEnrollmentModalOpen });
  };

  toggleUpdateUserInfoModal = () => {
    this.setState({ isUpdateUserInfoModalOpen: !this.state.isUpdateUserInfoModalOpen });
  };

  toggleEditRoleModal = () => {
    this.setState({ isEditRoleModalOpen: !this.state.isEditRoleModalOpen });
  };

  openEnrollmentModal = (user) => {
    this.setState({ isEnrollmentModalOpen: true, selectedUser: user });
  };

  openEditRoleModal = (user) => {
    this.setState({ isEditRoleModalOpen: true, selectedUser: user });
  };

  editUserInfo(user) {
    this.setState({ isUpdateUserInfoModalOpen: true, selectedUser: user });
  }

  async onUpdateUserInfoSubmit(event, user) {
    event.preventDefault();
    const frm = event.target;
    try {
      await api.updateUserName(frm.firstName.value, frm.lastName.value, user.id);

      this.toggleUpdateUserInfoModal();
      this.getUsers();
    } catch (ex) {
      alert(ex);
    }
  }

  onEnrollmentFilter = (values) => {
    this.setState({ enrollmentFilterValue: values || [] });
  };

  onRoleFilter = (values) => {
    this.setState({ roleFilterValue: values || [] });
  };

  handleSearch = (e) => {
    var searchText = e.target.value;
    const searchUser = this.state.lstTempUsers.filter((user) => {
      if (user.name?.includes(searchText) || user.username?.includes(searchText) || user.email?.includes(searchText) || user.id?.includes(searchText) || user.role?.includes(searchText)) return user;
      else return null;
    });
    this.setState({ lstUsers: searchUser });
  };

  render() {
    let users = this.state.lstUsers;
    if (this.state.enrollmentFilterValue.length > 0) {
      const arySelectedEnrollments = this.state.enrollmentFilterValue.map((item) => item.value);
      users = users.filter((user) => {
        if (user.enrollments) {
          for (const bootcampId in user.enrollments) {
            if (arySelectedEnrollments.indexOf(bootcampId) >= 0 && user.enrollments[bootcampId]) {
              return true;
            }
          }
        }
        return false;
      });
    }

    if (this.state.roleFilterValue.length > 0) {
      const arySelectedRoles = this.state.roleFilterValue.map((item) => item.value);
      users = users.filter((user) => {
        if (user.role) {
          for (const role of user.role) {
            if (arySelectedRoles.indexOf(role) >= 0) {
              return true;
            }
          }
        }
        return false;
      });
    }

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Users({users?.length})</h1>
            </div>
          </div>
          <Row className="spacing-1">
            <Col xs="12" md="4" className="col">
              <Input type="search" placeholder="Enter search text" onChange={this.handleSearch} />
            </Col>
            <Col xs="12" md="4" className="col" style={{ margin: "auto" }}>
              <Select onChange={this.onRoleFilter} options={this.roles} isMulti placeholder="Filter by Role" />
            </Col>
            <Col xs="12" md="4" className="col" style={{ margin: "auto" }}>
              <Select onChange={this.onEnrollmentFilter} options={this.allEnrollments} isMulti placeholder="Filter by Enrollment" />
            </Col>
          </Row>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>
                  Roles
                  <Loading></Loading>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(
                users.map((user, index) => {
                  const userName = user.firstName ? `${user.firstName} ${user.lastName}` : user.name;
                  return (
                    <tr key={index.id}>
                      <td>
                        <button className="btn btn-small" onClick={this.editUserInfo.bind(this, user)}>
                          <FontAwesomeIcon icon={faEdit} transform="shrink-2" size="xs" />
                        </button>{" "}
                        {userName}
                      </td>
                      <td>{user.email}</td>
                      <td>
                        <button className="btn btn-small" onClick={this.openEditRoleModal.bind(this, user)}>
                          <FontAwesomeIcon icon={faEdit} size="xs" />
                        </button>{" "}
                        {user.role.join()}
                      </td>
                      <td>
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            this.openEnrollmentModal(user);
                          }}
                        >
                          Enrollments({user.lstEnrollments.length})
                        </button>
                      </td>
                    </tr>
                  );
                }),
              )}
            </tbody>
          </table>
          {this.state.isEnrollmentModalOpen && <EnrollmentInfoModal isOpen={this.state.isEnrollmentModalOpen} toggle={this.toggleEnrollmentModal} user={this.state.selectedUser} refreshUsers={this.getUsers} />}
          {this.state.isUpdateUserInfoModalOpen && <UpdateUserInfoModal onSubmit={this.onUpdateUserInfoSubmit.bind(this)} isOpen={this.state.isUpdateUserInfoModalOpen} toggle={this.toggleUpdateUserInfoModal} user={this.state.selectedUser} />}
          {this.state.isEditRoleModalOpen && <EditRoleModal isOpen={this.state.isEditRoleModalOpen} toggle={this.toggleEditRoleModal} user={this.state.selectedUser} refreshUsers={this.getUsers} />}
        </div>
      </div>
    );
  }
}
