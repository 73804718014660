import React from "react";
import api from "../Services/ApiService";
import auth from "../Services/AuthService";

export default class Login extends React.Component {
  async componentDidMount() {
    const search = this.searchToObject(this.props.location.search);
    if (search.loginToken) {
      try {
        auth.setToken(search.loginToken);
        const userData = await api.getuserData();
        if (userData.user && userData.user.role.indexOf("admin") >= 0) {
          auth.setUserData(userData.user);
          // alert("You are logged in");
        } else {
          alert("Only admins can login. ");
          auth.setToken(null);
        }
        this.props.history.push("/");
      } catch (ex) {
        auth.setToken(null);
        alert("There was an error during login. ");
        this.props.history.push("/");
      }
    }
  }

  searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === "") continue;

      pair = pairs[i].split("=");
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  render() {
    return <div>Logging in...</div>;
  }
}
