import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Input, FormGroup, Label, Button, Alert } from "reactstrap";
// import XLSX from "xlsx";
// import { MDBDataTable } from 'mdbreact';
import Select from "react-select";
import api from "../../Services/ApiService";
import utils from "../../Services/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEdit } from "@fortawesome/free-solid-svg-icons";
import EditContactModal from "./EditContactModal";
import moment from "moment";
import renderUtils from "../../Services/RenderUtils";

import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "@bit/adeoy.utils.data-table";

class Mautic extends Component {
  state = {
    data: null,
    selectedTags: [],
    doneCourse: false,
    contacts: null,
    contactNoCache: false,
    loadAllContacts: false,
    tags: null,
    isEditContactModalOpen: false,
    selectedContact: null,
    filterDNC: "all",
    searchStr: "",
  };

  columns = [
    { title: "Action", data: "action" },
    { title: "Email", data: "email" },
    { title: "First name", data: "firstName" },
    { title: "Last name", data: "lastName" },
    { title: "Recent use", data: "lastActiveDate" },
    { title: "Tags", data: "tagsStr" },
    { title: "Points", data: "points" },
    { title: "Mobile", data: "mobileStr" },
    { title: "DNC", data: "dnc" },
    // { title: 'Last Ip Address', prop: 'lastIpAddress',  width: 100,filterable:true,sortable:true },
  ];
  searchableColumns = this.columns.filter((item) => item.search);

  componentDidMount() {
    this.loadTags();
  }

  async loadTags(noCache = false) {
    try {
      let tags = null;
      if (noCache) tags = await api.mauticetTags();
      else tags = await api.mauticGetTagsWithCache();

      tags = utils.GroupObject(tags.tags).map((item) => ({ label: item.tag, value: item.tag }));
      this.setState({ tags: tags });
    } catch (ex) {
      console.log("Error while getting tags ", ex);
      alert("Could not get tags. ");
    }
  }
  onTagSelectionChange(values) {
    this.setState({ selectedTags: values || [] });
  }

  filterSelectedTags(filterData) {
    let data = filterData;
    data = data.filter((item) => {
      // Apply Search filter.
      if (
        this.state.searchStr &&
        this.searchableColumns.every((column) => {
          try {
            return !item[column.prop].toString().toLowerCase().includes(this.state.searchStr.toLowerCase());
          } catch (ex) {
            return true;
          }
        })
      ) {
        return false;
      }

      if (this.state.doneCourse) return this.state.selectedTags.every((objTag) => item[objTag.value]);
      else return this.state.selectedTags.every((objTag) => !item[objTag.value]);
    });

    if (this.state.filterDNC !== "all") {
      if (this.state.filterDNC === "dnc-only") data = data.filter((item) => item.doNotContact.length > 0);
      else if (this.state.filterDNC === "non-dnc-only") data = data.filter((item) => item.doNotContact.length <= 0);
    }
    return data;
  }

  onContactEditClick(contact) {
    this.setState({ isEditContactModalOpen: true, selectedContact: contact });
  }

  async getContacts() {
    try {
      const contactsData = await api.mauticGetContacts(this.state.contactNoCache, !this.state.loadAllContacts);
      const contacts = contactsData.map((item) => {
        try {
          // item = {...item, ...item.fields.core};
          item.firstName = item.fields.core.firstname.value;
          item.lastName = item.fields.core.lastname.value;
          item.email = item.fields.core.email.value;
          item.mobile = item.fields.core.mobile.value;
          item.tagsStr = item.tags
            .map((item2) => {
              item[item2.tag] = true;
              return item2.tag;
            })
            .join();
          item.mobileStr = <a href={`sms:${item.fields.core.mobile.value}&body=hello`}>{item.fields.core.mobile.value}</a>;

          item.action = (
            <Button color="info" onClick={this.onContactEditClick.bind(this, item)} size="sm">
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          );
          // Object.defineProperty(item, "dnc", {
          //     get: this.renderDNC.bind(this, item)
          // });
          item.dnc = this.renderDNC(item);
          if (item.lastActive) {
            item.lastActiveDate = moment().diff(moment(item.lastActive), "hours");
          }
        } catch (ex) {
          console.log("Error while loading contacts", ex);
        }
        return item;
      });
      // console.log(contacts);
      this.setState({ contacts });
    } catch (ex) {
      console.log("Error occurred whiling fetching mautic contacts.", ex);
    }
  }

  renderDNC(item) {
    return (
      <input
        type="checkbox"
        checked={item.doNotContact.length > 0}
        onChange={() => {
          if (item.doNotContact.length > 0) this.removeFromDNC(item);
          else this.addToDNC(item);
        }}
      />
    );
  }

  toggleEditContactModal() {
    this.setState({ isEditContactModalOpen: !this.state.isEditContactModalOpen });
  }

  async onEditContactSubmit(values) {
    let tagsStr = "";
    if (values.tags) {
      tagsStr = values.tags.map((item) => item.value).join();
    }

    try {
      await api.mauticEditContact(this.state.selectedContact.id, values.firstName, values.lastName, values.mobile, values.points, this.state.tags.map((item) => "-" + item.value).join() + (tagsStr ? "," + tagsStr : ""));
      // alert("Updated Successfully");
      // console.log(this.state.tags.map((item) => "-" + item.value).join() + (tagsStr ? "," + tagsStr : ""));
      this.selectedContact.setState({ firstName: values.firstName });
      this.selectedContact.setState({ lastName: values.lastName });
      this.selectedContact.setState({ mobile: values.mobile });
      this.selectedContact.setState({ mobileStr: <a href={`sms:${values.mobile}&body=hello`}>{values.mobile}</a> });
      this.selectedContact.setState({ points: values.points });
      if (values.tags) this.selectedContact.setState({ tags: values.tags.map((item) => ({ id: 0, tag: item.value })) });
      else this.selectedContact.setState({ tags: [] });
      this.selectedContact.setState({ tagsStr: tagsStr });

      this.toggleEditContactModal();
    } catch (ex) {
      alert(ex);
      console.log(ex);
    }
  }

  async removeFromDNC(item) {
    try {
      await api.mauticRemoveFromDNC(item.id);
      item.doNotContact.pop();
      item.dnc = this.renderDNC(item);
      this.forceUpdate();
    } catch (ex) {
      alert("Error: " + ex.message);
    }
  }

  async addToDNC(item) {
    try {
      await api.mauticAddDNC(item.id);
      item.doNotContact.push("");
      item.dnc = this.renderDNC(item);
      this.forceUpdate();
    } catch (ex) {
      alert("Error: " + ex.message);
    }
  }

  onSort(value) {
    if (value.column) utils.sortArrayOfObject(this.state.contacts, value.column, value.direction);
  }

  render() {
    let contactToShow = [];
    if (this.state.contacts) contactToShow = this.filterSelectedTags(this.state.contacts);
    return (
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md="6" xs="12">
                    <Button onClick={this.getContacts.bind(this)}>Get Contacts</Button>

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.contactNoCache}
                          onChange={(e) => {
                            this.setState({ contactNoCache: e.target.checked });
                          }}
                        />{" "}
                        Ignore cache
                      </Label>
                      <br></br>

                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.loadAllContacts}
                          onChange={(e) => {
                            this.setState({ loadAllContacts: e.target.checked });
                          }}
                        />{" "}
                        Get everything
                      </Label>
                    </FormGroup>
                  </Col>
                  {this.state.contacts && (
                    <React.Fragment>
                      <Col md="3" xs="12">
                        <input
                          type="radio"
                          id="radioCourseDone"
                          checked={this.state.doneCourse}
                          onChange={() => {
                            this.setState({ doneCourse: true });
                          }}
                        />
                        <label htmlFor="radioCourseDone">With tags &nbsp;&nbsp;</label>
                        <br></br>
                        <input
                          type="radio"
                          id="radioCourseNotDone"
                          checked={!this.state.doneCourse}
                          onChange={() => {
                            this.setState({ doneCourse: false });
                          }}
                        />
                        <label htmlFor="radioCourseNotDone">Without tags</label>
                      </Col>
                      <Col md="3" xs="12">
                        {this.state.tags && (
                          <React.Fragment>
                            <Select onChange={this.onTagSelectionChange.bind(this)} options={this.state.tags} isMulti placeholder="Select" />
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.loadTags(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faSync} />
                            </Button>
                          </React.Fragment>
                        )}
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
                <Row>
                  {this.state.contacts && (
                    <Col xs="12" md="4">
                      <Alert color="info">
                        Showing {contactToShow.length} Hiding {this.state.contacts.length - contactToShow.length} Total {this.state.contacts.length}
                      </Alert>
                    </Col>
                  )}
                  <Col xs="12" md="4">
                    <select
                      className="form-control"
                      value={this.state.filterDNC}
                      onChange={(e) => {
                        this.setState({ filterDNC: e.target.value });
                      }}
                    >
                      <option value="all">Show all</option>
                      <option value="non-dnc-only">OK to contact</option>
                      <option value="dnc-only">Do not contact</option>
                    </select>
                  </Col>
                  <Col xs="12" md="4">
                    {renderUtils.renderTextField(this, "searchStr", "Search", { type: "text" })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.contacts && (
          <Row>
            <Col>
              <DataTable data={contactToShow} columns={this.columns} striped={true} hover={true} responsive={true} />
            </Col>
          </Row>
        )}

        {this.state.isEditContactModalOpen && (
          <EditContactModal isOpen={this.state.isEditContactModalOpen} toggle={this.toggleEditContactModal.bind(this)} contact={this.state.selectedContact} allTags={this.state.tags} onSubmit={this.onEditContactSubmit.bind(this)} />
        )}
      </Container>
    );
  }
}

export default Mautic;
