import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import moment from "moment";
import AddScheduleModal from "./AddScheduleModal";
import api from "../../Services/ApiService/index";
// import Datatable from "react-bs-datatable";
import enums from "../../../enums";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "@bit/adeoy.utils.data-table";

class Schedule extends Component {
  state = {
    lstSchedule: null,
    selectedSchedule: null,
    isAddScheduleModalOpen: false,
  };
  scheduleDayObj = {};

  columns = [
    { title: "Action", data: "action" },
    { title: "Schedule/Start Date", data: "scheduleDateStr" },
    { title: "Title", data: "title" },
    { title: "Description", data: "descriptionShort" },
    { title: "File Name", data: "file" },
    { title: "Schedule Type", data: "scheduleType" },
    { title: "Schedule Day", data: "scheduleDayShow" },
    { title: "Schedule Time", data: "scheduleTimeShow" },
    { title: "Total Occurrence", data: "totalOccurrence" },
    { title: "Repeat Every", data: "repeatEvery" },
    { title: "Vimeo Link", data: "vimeoLink" },
    { title: "Ready", data: "readyStr" },
    // { title: 'First name', prop: 'firstName', },
    // { title: 'Last name', prop: 'lastName', width: 100, sortable: true },
  ];

  componentDidMount() {
    Object.keys(enums.SCHEDULE_DAY_TYPE).forEach((item) => {
      this.scheduleDayObj[enums.SCHEDULE_DAY_TYPE[item]] = item;
    });
    this.getSchedule();
  }

  async getSchedule() {
    try {
      const schedule = await api.getScheduleAdmin();

      schedule.forEach((episode) => {
        episode.action = (
          <React.Fragment>
            <Button color="info" size="sm" onClick={this.onEditClick.bind(this, episode)}>
              Edit
            </Button>{" "}
            <Button color="danger" size="sm" onClick={this.onDeleteClick.bind(this, episode)}>
              Delete
            </Button>{" "}
            <Button color="dark" size="sm" onClick={this.onRepeatClick.bind(this, episode)}>
              Duplicate
            </Button>
          </React.Fragment>
        );
        episode.readyStr = <input type="checkbox" checked={episode.ready} onChange={this.onReadyChange.bind(this, episode)} />;
        // console.log(episode)
        episode.scheduleDateStr = moment(episode.scheduleDate).format("YYYY/MM/DD HH:MM:SS");
        episode.scheduleTimeShow = episode.scheduleTime ? moment(new Date(episode.scheduleTime)).format("h:mm A") : "";
        episode.scheduleDayShow = this.scheduleDayObj[episode.scheduleDay];
        episode.descriptionShort = episode.description.length > 50 ? episode.description.substr(0, 50) + "..." : episode.description;
      });
      // console.log(schedule)
      this.setState({ lstSchedule: schedule });
    } catch (ex) {
      alert(ex);
    }
  }

  toggleAddScheduleModal(show) {
    this.setState({ isAddScheduleModalOpen: typeof show === "boolean" ? show : !this.state.isAddScheduleModalOpen });
  }

  onClickAdd() {
    this.selectedSchedule = null;
    this.toggleAddScheduleModal();
  }

  onEditClick(episode) {
    this.selectedSchedule = episode;
    this.toggleAddScheduleModal();
  }

  onRepeatClick(episode) {
    this.selectedSchedule = { ...episode };
    delete this.selectedSchedule._id;
    this.toggleAddScheduleModal();
  }

  onReadyChange(episode) {
    episode.ready = !episode.ready;
    episode.scheduleDate = moment(episode.scheduleDate).toDate().toISOString();
    this.onAddScheduleSubmit(episode);
  }

  async onDeleteClick(episode) {
    if (!window.confirm("Are you sure you want to delete this schedule? ")) return;
    try {
      const result = await api.deleteSchedule(episode._id);
      console.log(result);

      this.getSchedule();
    } catch (ex) {
      alert(ex);
    }
  }

  async onAddScheduleSubmit(values) {
    try {
      const result = await api.addSchedule(values.scheduleDate, values.title, values.description, values.file, values.ready, values._id, values.scheduleType, values.scheduleDay, values.scheduleTime, values.totalOccurrence, values.repeatEvery);
      console.log(result);
      this.toggleAddScheduleModal(false);
      this.getSchedule();
    } catch (ex) {
      alert(ex);
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Button color="primary" onClick={this.onClickAdd.bind(this)}>
                  Add Schedule
                </Button>
                <br></br>
                <br></br>
                {this.state.lstSchedule && this.state.lstSchedule.length > 0 && (
                  <DataTable
                    data={this.state.lstSchedule}
                    columns={this.columns}
                    striped={true}
                    hover={true}
                    responsive={true}
                    // onClickRow={click}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.isAddScheduleModalOpen && (
          <AddScheduleModal
            isOpen={this.state.isAddScheduleModalOpen}
            toggle={this.toggleAddScheduleModal.bind(this)}
            data={this.selectedSchedule}
            // allTags={this.state.tags}
            onSubmit={this.onAddScheduleSubmit.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Schedule;
