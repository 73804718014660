import React, { Component } from "react";
import * as RS from "reactstrap";

class CommonModal extends Component {
  render() {
    const { children, header, bodyProps, size } = this.props;
    const closeBtn = (
      <button className="close" onClick={this.props.toggle}>
        &times;
      </button>
    );
    return (
      <RS.Modal size={size || "lg"} isOpen={this.props.isOpen} toggle={this.props.toggle}>
        {header && <RS.ModalHeader close={closeBtn}>{header}</RS.ModalHeader>}
        <RS.ModalBody {...bodyProps}>{children}</RS.ModalBody>
      </RS.Modal>
    );
  }
}

export default CommonModal;
